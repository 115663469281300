/* eslint-disable */

import { ArrowDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  Icon,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Input,
} from '@chakra-ui/react';
import { Button, ButtonGroup } from '@chakra-ui/react'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import Card from 'components/card/Card';
import React, { useState, useEffect } from 'react';
import { MdArrowUpward, MdCancel, MdCheckCircle, MdError } from 'react-icons/md';

import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react'

const columnHelper = createColumnHelper();
const lightModeColors = [
  '#FF5733', 
  '#33FF57',  
  '#3357FF',  
  '#FF33A1',  
  '#FFC300',  
];

const darkModeColors = [
  '#C70039', 
  '#28A745', 
  '#007BFF', 
  '#FF69B4', 
  '#FFC300',
];

const userColors = {};

const getUserColor = (userId, isDarkMode) => {
  if (userColors[userId]) {
    return userColors[userId];
  }
  const colors = isDarkMode ? darkModeColors : lightModeColors;
  const randomColor = colors[Math.floor(Math.random() * colors.length)];
  userColors[userId] = randomColor;
  return randomColor;
};


export default function OrdersTable(props) {
  const { tableData } = props;
  const [filterInput, setFilterInput] = useState({});
  const [sorting, setSorting] = React.useState([]);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  let defaultData = tableData;
  const [loadingRows, setLoadingRows] = useState({});

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState(''); // 'success' or 'error'

  const alertStuffs = (type, message) =>{
    setAlertType(type);
    setAlertMessage(message);
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 5000);
  };

  const handleCancel = (rowData) => {
    setLoadingRows((prev) => ({ ...prev, [rowData.order_id]: true })); // Set loading for the specific row
    const cancelData = {
      symbol: rowData.symbol,
      username: rowData.username,
      market: rowData.market,
      order_id: rowData.order_id,
    };
    fetch(`${process.env.REACT_APP_API_BASE_URL}/order/`, {
      
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(cancelData),
    })
    .then(response => response.json())
    .then(data => {
      setLoadingRows((prev) => ({ ...prev, [rowData.order_id]: false })); // Reset loading for the specific row
      console.log('Order cancellation successful:', data);
      if (data['status'] === 'SUCCESS'){
        alertStuffs('success', `Order cancelled successfully for ${data['username']} with Order ID: ${data['data']['id']}`);
      } else {
        alertStuffs('error', `Failed to cancel order because ${data['message']}`);
      }
    })
    .catch((error) => {
      setLoadingRows((prev) => ({ ...prev, [rowData.order_id]: false })); // Reset loading for the specific row
      console.error('Error cancelling order:', error);
      alertStuffs('error', `Failed to cancel order`);
    });
  };

  const CreateCancelAlert = () => {
    return (
      <Alert
        status={alertType}
        variant='subtle'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        textAlign='center'
        height='200px'
      >
        <AlertIcon boxSize='40px' mr={0} />
        <AlertTitle mt={4} mb={1} fontSize='lg'>
          {alertType === 'success' ? 'Order Submitted!' : 'Order Failed!'}
        </AlertTitle>
        <AlertDescription maxWidth='sm'>
          {alertMessage}
        </AlertDescription>
      </Alert>
    );
  };

  const handleFilterChange = (columnId, value) => {
    setFilterInput((prev) => ({
      ...prev,
      [columnId]: value,
    }));
  };

  useEffect(() => {
    const filteredData = tableData.filter((row) =>
      columns.every((column) => {
        const columnId = column.id;
        const filterValue = filterInput[columnId];
        if (!filterValue) return true;
        return row[columnId]?.toString().toLowerCase().includes(filterValue.toLowerCase());
      })
    );
    setData(filteredData);
  }, [filterInput, tableData]);

  const columns = [
    columnHelper.accessor('order_id', {
      id: 'order_id',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          order Id
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('timestamp', {
      id: 'timestamp',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Timestamp
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {new Date(info.getValue()).toLocaleString()}
        </Text>
      ),
    }),
    columnHelper.accessor('side', {
      id: 'side',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Side
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Icon
            w="24px"
            h="24px"
            me="5px"
            color={
              info.getValue() === 'BUY'
                ? 'green.500'
                : 'red.500'
            }
            as={
              info.getValue() === 'BUY'
                ? MdArrowUpward
                : ArrowDownIcon
            }
          />
        <Text color={info.getValue() == 'BUY' ? 'teal.500' : 'orange.400'} fontSize="sm" fontWeight="700">
        {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('symbol', {
      id: 'symbol',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          symbol
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('quantity', {
      id: 'quantity',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Quantity
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Icon
            w="24px"
            h="24px"
            me="5px"
            color={info.getValue() === 'BUY' ? 'green.500' : 'red.500'}
            as={info.getValue() === 'BUY' ? MdArrowUpward : ArrowDownIcon}
          />
          <Text color={info.getValue() === 'BUY' ? 'green.500' : 'red.500'} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('price', {
      id: 'price',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Price
        </Text>
      ),
      cell: (info) => (
        <Text color={info.getValue() === 'SPOT' ? 'teal.500' : 'orange.400'} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    })
  ];
  const inputTextColor = useColorModeValue('gray.800', 'whiteAlpha.900');
  const [data, setData] = React.useState(() => [...defaultData]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });
  return (
    <Card
      flexDirection="column"
      w="100%"
      px="0px"
      overflowX="scroll"
    >
      <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          Orders
        </Text>
      </Flex>
      {showAlert && <CreateCancelAlert />}
      <Box>
        <Table variant="simple" color="gray.500" mb="24px" mt="12px">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Th
                      key={header.id}
                      colSpan={header.colSpan}
                      pe="10px"
                      borderColor={borderColor}
                      cursor="pointer"
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <Box>
                        <Flex
                          justifyContent="space-between"
                          align="center"
                          fontSize={{ sm: '10px', lg: '12px' }}
                          color="gray.400"
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                          {{
                            asc: '',
                            desc: '',
                          }[header.column.getIsSorted()] ?? null}
                        </Flex>
                        <Input
                            my="2"
                            placeholder=""
                            value={filterInput[header.column.columnDef.id] || ''}
                            onChange={(e) => handleFilterChange(header.column.columnDef.id, e.target.value)}
                            onClick={(e) => e.stopPropagation()}
                            color={inputTextColor}
                        />
                      </Box>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table
              .getRowModel()
              .rows
              .map((row) => {
                return (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Td
                          key={cell.id}
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor="transparent"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </Box>
    </Card>
  );
}
