/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { Box, SimpleGrid } from "@chakra-ui/react";
import OiBuildTable from "views/admin/oi_build/components/OiBuildTable";
import {
  columnDataPosition
} from "views/admin/dataTables/variables/ToiColumns";
import React , {useEffect, useState} from "react";


const processPosition = (item) => {
  // Create a new object to avoid mutating the original item
  const newItem = { ...item };
  // Iterate through the object keys
  Object.keys(newItem).forEach(key => {
    if (typeof newItem[key] === 'number') {
      newItem[key] = Math.round(newItem[key] * 1000) / 1000; // Round to 3 decimal places
    }
  });
  return newItem;
};

export default function Settings() {
  const [oiBuild, setOiBuild] = useState([]);

  useEffect(() => {
    const fetchOiBuildUp = async () => {
      try {
        console.log("API Base URL:", process.env.REACT_APP_API_BASE_URL);
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/oi/`);
        const data = await response.json();
        const processedData = data.map(processPosition);
        setOiBuild(processedData);
      } catch (error) {
        console.error('Error fetching OiBuild:', error);
      }
    };
    fetchOiBuildUp();
    // Fetch logs every second
    const intervalId = setInterval(fetchOiBuildUp, 1000 * 60 * 5);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb='20px'
        columns={{ base: 1, md: 1, xl: 1 }}
        spacing={{ base: "20px", xl: "20px" }}>
        <OiBuildTable
          columnsData={columnDataPosition}
          tableData={oiBuild}
        />
      </SimpleGrid>
    </Box>
  );
}
