/* eslint-disable */

import { ArrowDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  Icon,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Input,

} from '@chakra-ui/react';
import { Button, ButtonGroup } from '@chakra-ui/react'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import Card from 'components/card/Card';
import Menu from 'components/menu/MainMenu';
import React, { useState, useEffect } from 'react';
// Assets
import { MdArrowUpward, MdCancel, MdCheckCircle, MdOutlineError } from 'react-icons/md';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react'
const columnHelper = createColumnHelper();
const lightModeColors = [
  '#FF5733', 
  '#33FF57',
  '#3357FF', 
  '#FF33A1',
  '#FFC300',
];

const darkModeColors = [
  '#C70039', 
  '#28A745',
  '#007BFF',
  '#FF69B4',
  '#FFC300',
];

const userColors = {};

const getUserColor = (userId, isDarkMode) => {
  if (userColors[userId]) {
    return userColors[userId];
  }
  
  const colors = isDarkMode ? darkModeColors : lightModeColors;
  const randomColor = colors[Math.floor(Math.random() * colors.length)];
  
  userColors[userId] = randomColor;
  
  return randomColor;
};
export default function OiBuildTable(props) {
  const { tableData } = props;
  const [filterInput, setFilterInput] = useState({});
  const [sorting, setSorting] = React.useState([]);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  let defaultData = tableData;

  const BuildUpBackgroundColor = {
    'LONG BUILDUP': 'green.200',
    'SHORT BUILDUP': 'red.200',
    'LONG UNWINDING': 'yellow.200',
    'SHORT COVERING': 'orange.200',
  }


  const handleFilterChange = (columnId, value) => {
    setFilterInput((prev) => ({
      ...prev,
      [columnId]: value,
    }));
  };
  const inputTextColor = useColorModeValue('gray.800', 'whiteAlpha.900');
  useEffect(() => {
    const filteredData = tableData.filter((row) =>
      columns.every((column) => {
        const columnId = column.id;
        const filterValue = filterInput[columnId];
        if (!filterValue) return true;
        return row[columnId]?.toString().toLowerCase().includes(filterValue.toLowerCase());
      })
    );
    setData(filteredData);
  }, [filterInput, tableData]);

  const columns = [
    columnHelper.accessor('delta_oi_pe', {
      id: 'delta_oi_pe',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Delta OI PE
        </Text>
      ),
      cell: (info) => (
        <Text 
        color={info.getValue() > 0 ? 'green.400' : 'red.500'}
        fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('oi_pe', {
      id: 'oi_pe',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          OI PE
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('delta_ltp_pe', {
      id: 'delta_ltp_pe',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Delta LTP PE
        </Text>
      ),
      cell: (info) => (
        <Text 
        color={info.getValue() > 0 ? 'green.400' : 'red.500'}
        fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('ltp_pe', {
      id: 'ltp_pe',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          LTP PE
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('build_pe', {
      id: 'build_pe',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Build PE
        </Text>
      ),
      cell: (info) => (
        <Text color='black'
        backgroundColor= {BuildUpBackgroundColor[info.getValue()]}
        fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('underlying', {
      id: 'underlying',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Underlying
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('strike', {
      id: 'strike',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Strike
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('time', {
      id: 'time',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Time
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('expiry', {
      id: 'expiry',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Expiry
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('build_ce', {
      id: 'build_ce',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Build CE
        </Text>
      ),
      cell: (info) => (
        <Text color='black'
        backgroundColor= {BuildUpBackgroundColor[info.getValue()]}
        fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('ltp_ce', {
      id: 'ltp_ce',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          LTP CE
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('delta_ltp_ce', {
      id: 'delta_ltp_ce',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Delta LTP CE
        </Text>
      ),
      cell: (info) => (
        <Text 
        color={info.getValue() > 0 ? 'green.400' : 'red.500'}
        fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('oi_ce', {
      id: 'oi_ce',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          OI CE
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('delta_oi_ce', {
      id: 'delta_oi_ce',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Delta OI CE
        </Text>
      ),
      cell: (info) => (
        <Text
        color={info.getValue() > 0 ? 'green.400' : 'red.500'}
        fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
  ];

  const [data, setData] = React.useState(() => [...defaultData]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });
  return (
    <Card
      flexDirection="column"
      w="100%"
      px="0px"
      overflowX="scroll"
    >
      <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          Positions
        </Text>
      </Flex>
      <Box>
        <Table variant="simple" color="gray.500" mb="24px" mt="12px">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Th
                      key={header.id}
                      colSpan={header.colSpan}
                      pe="10px"
                      borderColor={borderColor}
                      cursor="pointer"
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <Flex
                        justifyContent="space-between"
                        align="center"
                        fontSize={{ sm: '10px', lg: '12px' }}
                        color="gray.400"
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                        {{
                          asc: '',
                          desc: '',
                        }[header.column.getIsSorted()] ?? null}
                      </Flex>
                      <Input
                            my="2"
                            placeholder=""
                            value={filterInput[header.column.columnDef.id] || ''}
                            onChange={(e) => handleFilterChange(header.column.columnDef.id, e.target.value)}
                            onClick={(e) => e.stopPropagation()}
                            color={inputTextColor}
                        />
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table
              .getRowModel()
              .rows
              .map((row) => {
                return (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Td
                          key={cell.id}
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor="transparent"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </Box>
    </Card>
  );
}
