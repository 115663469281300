import React from 'react';

import { Icon } from '@chakra-ui/react';
import {
  MdBarChart,
  MdCreditCard,
  MdLocalGasStation,
  MdOutlineShoppingCart,
} from 'react-icons/md';

// Admin Imports
import Orders from 'views/admin/orders';
import DataTables from 'views/admin/dataTables';
import LogsTable from 'views/admin/logs';
import OiBuild from 'views/admin/oi_build';
// Auth Imports

const routes = [
  {
    name: 'Orders',
    layout: '/admin',
    path: '/nft-marketplace',
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: <Orders />,
    secondary: true,
  },
  {
    name: 'Positions',
    layout: '/admin',
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: '/data-tables',
    component: <DataTables />,
  },
  {
    name: 'Logs',
    layout: '/admin',
    icon: <Icon as={MdLocalGasStation} width="20px" height="20px" color="inherit" />,
    path: '/logs',
    component: <LogsTable />,
  },
  {
    name: 'OI buildUp',
    layout: '/admin',
    icon: <Icon as={MdCreditCard} width="20px" height="20px" color="inherit" />,
    path: '/oi_build',
    component: <OiBuild />,
  }
];

export default routes;
