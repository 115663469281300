export const columnDataPosition = [
  {
    Header: "Username",
    accessor: "username",
  },
  {
    Header: "Entry Order ID",
    accessor: "entry_order_id",
  },
  {
    Header: "Exit Order ID",
    accessor: "exit_order_id",
  },
  {
    Header: "Symbol",
    accessor: "symbol",
  },
  {
    Header: "Current Price",
    accessor: "current_price",
  },
  {
    Header: "Profit",
    accessor: "profit",
  },
  {
    Header: "Stop Loss",
    accessor: "stoploss",
  },
  {
    Header: "Take Profit",
    accessor: "takeprofit",
  },
  {
    Header: "Market",
    accessor: "market",
  },
  {
    Header: "Direction",
    accessor: "direction",
  },
  {
    Header: "Status",
    accessor: "status",
  }
];

export const columnDataOrders = [
  {
    Header: "Order ID",
    accessor: "order_id",
  },
  {
    Header: "Symbol",
    accessor: "symbol",
  },
  {
    Header: "Username",
    accessor: "username",
  },
  {
    Header: "Quantity",
    accessor: "quantity",
  },
  {
    Header: "Price",
    accessor: "price",
  },
  {
    Header: "Timestamp",
    accessor: "timestamp",
  },
  {
    Header: "Price Ex",
    accessor: "average_price_executed",
  },
  {
    Header: "Quant. Ex.",
    accessor: "quantity_executed",
  },
  {
    Header: "Leverage",
    accessor: "leverage",
  },
  {
    Header: "Order Type",
    accessor: "order_type",
  },
  {
    Header: "Side",
    accessor: "side",
  },
  {
    Header: "Market",
    accessor: "market",
  },
  {
    Header: "Status",
    accessor: "status",
  },

];

export const columnDataLogs = [
  {
    Header: "username",
    accessor: "username",
  },
  {
    Header: "Log Type",
    accessor: "log_type",
  },
  {
    Header: "Timestamp",
    accessor: "timestamp",
  },
  {
    Header: "Description",
    accessor: "log",
  },
];